import { graphql } from "gatsby"
import { Link } from "gatsby"
import React from "react"

import Layout from "../componets/Layout"
import * as styles from "../styles/home.module.css"
import { GatsbyImage } from "gatsby-plugin-image"
import clockgif from "../gifs/clock.gif"
import headerImage from "../svg/data.svg"
import mysvg from "../svg/data.svg"

export default function Home({ data }) {
  return (
    <Layout>
      <section className={styles.header}>
        <div>
          <h2>WyredAnalytics</h2>
          <p>
            We're aggregating data into a single collection point. Using our
            APIs you can pull data ranging from Climate, Census, Elections
            e.t.c.
          </p>
          <h3>Coming soon ...</h3>
          <img src={clockgif} alt="clockgif" width="30" height="30" />
        </div>
        <div>
          <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Banner {
    file(relativePath: { eq: "businessprofit.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
